import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import {
  GetRecipesOptions,
  PartialRecipe,
  Recipe,
  RateRecipeResponse,
} from '../types/api/entities/recipe';
import { Paginated } from '../types/api/paginated';
import { RecipeFilter } from '../types/api/entities/recipe-filter';
import { Store } from '../types/api/entities/store';
import { CustomAppProps } from '../types/app';
import { ShopifyProductVariant } from '../types/api/entities/shopify';

export function AxiosWithApi({ cookie, apiBaseUrl }: CustomAppProps) {
  const axiosOptions: AxiosRequestConfig = {
    withCredentials: true,
    baseURL: apiBaseUrl,
  };

  const http = Axios.create(axiosOptions);

  if (cookie) {
    http.defaults.headers.cookie = cookie;
  }

  http.interceptors.response.use(
    (res) => {
      console.log('%c⧭', 'color: #6fe500', 'API Success:', res.status);
      return res;
    },
  );

  return http;
}

export type StoreExpandableProperty = 'plan' | 'payment_method';

export interface GetStoreOptions {
  expand?: StoreExpandableProperty[];
}

export class ApiService {
  constructor(private readonly http: AxiosInstance) { }

  public getStore(params: GetStoreOptions = {}): Promise<Store> {
    const res = this.http.get<Store>('/store', { params }).then((res) => res.data);
    return res;
  }

  public getRecipe(idOrSlug: number | string): Promise<Recipe> {
    return this.http.get(`/recipe/${idOrSlug}`).then(({ data }) => {
      data.filters = data.filters.flatMap((filter: RecipeFilter) =>
        filter.options.map((option) => option.id),
      );
      return data;
    });
  }

  public getRecipes(params?: GetRecipesOptions): Promise<Paginated<PartialRecipe>> {
    let filters: Record<string, string> | undefined = undefined;

    if (params?.filters) {
      filters = params.filters.reduce<Record<string, string>>((result, filter, index) => {
        result[`filters[${index}][filterLabel]`] = filter.filterLabel;
        result[`filters[${index}][optionSlug]`] = filter.optionSlug;
        return result;
      }, {});
    }

    return this.http
      .get<Paginated<PartialRecipe>>('/recipe', {
        params: {
          ...params,
          ...filters,
          filters: undefined,
          draft: false,
        },
      })
      .then((res) => res.data);
  }

  public getRecipeFilters(): Promise<RecipeFilter[]> {
    return this.http
      .get<RecipeFilter[]>('/recipe-filter')
      .then((res) => res.data)
      .catch(() => {
        console.error('Could not get recipe filter list. Error:');
        return [];
      });
  }

  public rateRecipe(recipeId: number, value: number): Promise<RateRecipeResponse> {
    return this.http
      .post<RateRecipeResponse>(`/recipe/${recipeId}/rating`, {
        rating: value,
      })
      .then((res) => res.data)
      .catch((e) => {
        console.log(`Could not get recipe ratings for recipe id: ${recipeId}. Error`);
        console.dir(e);
        throw e;
      });
  }

  public async getShopifyProductVariantsByIds(ids: string[]): Promise<ShopifyProductVariant[]> {
    if (ids.length === 0) {
      return [];
    }

    return this.http
      .get('/shopify/product-variant', {
        params: {
          ids: ids.join(','),
        },
      })
      .then((res) => res.data)
      .catch((e) => {
        throw new Error(`Could not get shopify product variants: ${e.message}`);
      });
  }
}
