import { useMemo } from 'react';
import { applyMiddleware, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { reducer } from './reducers';
import { StoreState } from './state';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas/rootSaga';
import { CustomAppProps } from '../types/app';
import { StoreAction } from './actions';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

let store: any; // TODO: update type

const sagaMiddleware = createSagaMiddleware();

function initStore(preloadedState: StoreState, { apiBaseUrl, cookie }: CustomAppProps) {
  const store = createStore(
    reducer,
    preloadedState,
    composeWithDevTools(applyMiddleware(sagaMiddleware)),
  );

  sagaMiddleware.run(rootSaga.bind(null, { apiBaseUrl, cookie }));

  return store;
}

export const initializeStore = (
  preloadedState: StoreState,
  { apiBaseUrl, cookie }: CustomAppProps,
): Store<StoreState, StoreAction> & {
  dispatch: unknown;
} => {
  let _store = store ?? initStore(preloadedState, { apiBaseUrl, cookie });

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = initStore(
      {
        ...store.getState(),
        ...preloadedState,
      },
      { apiBaseUrl, cookie },
    );

    store = undefined;
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') {
    return _store;
  }

  // Create the store once in the client
  if (!store) {
    store = _store;
  }

  return _store;
};

export type AppDispatch = ReturnType<typeof initializeStore>['dispatch'];
export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<StoreState> = useSelector;
export function useStore(initialState: StoreState, { apiBaseUrl, cookie }: CustomAppProps) {
  const store = useMemo(
    () => initializeStore(initialState, { apiBaseUrl, cookie }),
    [initialState, apiBaseUrl, cookie],
  );

  return store;
}
