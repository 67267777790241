import { Recipe } from '../../../types/api/entities/recipe';

export interface SetRecipeAction {
  type: 'SET_RECIPE';
  payload: Recipe;
}

export function setRecipe(payload: Recipe): SetRecipeAction {
  return {
    type: 'SET_RECIPE',
    payload,
  };
}
