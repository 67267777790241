import { RateRecipeResponse } from '../../../types/api/entities/recipe';

export interface UpdateRecipeRatingAction {
  type: 'UPDATE_RECIPE_RATING';
  payload: {
    recipeId: number;
    userRating: number;
  } & RateRecipeResponse;
}

// tslint:disable-next-line:max-line-length
export function updateRecipeRating(
  recipeId: number,
  userRating: number,
  response: RateRecipeResponse,
): UpdateRecipeRatingAction {
  return {
    type: 'UPDATE_RECIPE_RATING',
    payload: {
      recipeId,
      userRating,
      ...response,
    },
  };
}
