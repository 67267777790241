import { EntitiesState } from '../state/entities';
import { StoreAction } from '../actions';
import { SignupSource, StoreStatus } from '../../types/api/entities/store';

const initialState: EntitiesState = {
  store: {
    id: 0,
    name: '',
    subdomain: '',
    customDomain: '',
    website: '',
    status: StoreStatus.Active,
    signupSource: SignupSource.CommerceOwl,
    rootUrl: null,
    gaId: '',
  },

  recipe: {
    byId: {},
    query: {
      current: {
        ids: [],
        total: 0,
      },
    },
  },
  filter: {
    byId: {},
    allIds: [],
    idByOptionId: {},
  },
  option: {
    byId: {},
    allIds: [],
  },
};

export function entitiesReducer(state = initialState, action: StoreAction): EntitiesState {
  switch (action.type) {
    case 'SET_STORE':
      return {
        ...state,
        store: action.payload,
      };

    case 'SET_RECIPE':
      return {
        ...state,
        recipe: {
          ...state.recipe,
          byId: {
            ...state.recipe.byId,
            [action.payload.id]: {
              ...action.payload,
              related: action.payload.related && action.payload.related.map((item: any) => item.id),
            },
            ...(action.payload.related &&
              action.payload.related.reduce((prev: any, curr: any) => {
                prev[curr.id] = curr;
                return prev;
              }, {})),
          },
        },
      };

    case 'UPDATE_RECIPE_RATING': {
      const { rating, ratingCount, recipeId, userRating } = action.payload;

      const existingRecipeById = state.recipe.byId[recipeId];
      if (existingRecipeById === undefined) {
        return { ...state };
      }

      return {
        ...state,
        recipe: {
          ...state.recipe,
          byId: {
            ...state.recipe.byId,
            [recipeId]: {
              ...existingRecipeById,
              userRating,
              rating,
              ratingCount,
            },
          },
        },
      };
    }

    case 'SET_RECIPES': {
      const { recipe } = state;
      return {
        ...state,
        recipe: {
          byId: {
            ...recipe.byId,
            ...action.byId,
          },
          query: {
            current: action.value,
          },
        },
      };
    }

    case 'SET_CURRENT_RECIPES': {
      return {
        ...state,
        recipe: {
          ...state.recipe,
          query: {
            ...state.recipe.query,
            current: action.payload,
          },
        },
      };
    }

    case 'SET_FILTERS': {
      const option: EntitiesState['option'] = {
        byId: {},
        allIds: [],
      };
      const filterIdsByOptionId: Record<number, number> = {};

      const { allIds, byId } = action.payload;

      allIds.forEach((filterId) => {
        byId[filterId]?.options.forEach((item) => {
          option.byId[item.id] = item;
          option.allIds.push(item.id);

          filterIdsByOptionId[item.id] = filterId;
        });
      });

      return {
        ...state,
        filter: {
          ...action.payload,
          idByOptionId: filterIdsByOptionId,
        },
        option,
      };
    }

    default:
      return state;
  }
}
