export interface FontFamily {
  name: string;
  url: string;
}

export interface LatestRecipeHero {
  type: 'latest-recipe';
}

export interface CustomBannerHero {
  type: 'custom-banner';
  text: string;
  imageUrls: {
    S: string;
    M: string;
    L: string;
    original: string;
  };
}

export type StoreHeroSettings = LatestRecipeHero | CustomBannerHero;

export interface StoreTheme {
  primaryColor?: string;
  mainBackgroundColor?: string;
  footerBackgroundColor?: string;
  headingFontFamily?: FontFamily;
  hero?: StoreHeroSettings;
  deactivateAuthor?: boolean;
  deactivateRatings?: boolean;
}

export enum StoreStatus {
  Trialing = 'trialing',
  TrialWillEnd = 'trial_will_end',
  TrialExpired = 'trial_expired',
  Active = 'active',
  Delinquent = 'delinquent',
  Unpaid = 'unpaid',
  Canceled = 'canceled',
}

export enum SignupSource {
  CommerceOwl = 'CommerceOwl',
  Shopify = 'Shopify',
}

export interface StoreHeaderMenuItemTarget {
  type: 'url' | 'meta';
  value: string;
}

export interface StoreHeaderMenuItem {
  id: string;
  label: string;
  target: StoreHeaderMenuItemTarget;
  children?: StoreHeaderMenuItem[];
}

export interface StoreHeaderMenu {
  items: StoreHeaderMenuItem[];
}

export interface PlanFeature {
  label: string;
  type?: 'max_recipes' | 'max_users';
  value?: number | 'unlimited';
}

export interface Plan {
  id: string;
  amount: number;
  currency: string;
  interval: 'day' | 'week' | 'month' | 'year';
  interval_count: number;
  product_name: string;
  trial_period_days?: number;
  features: PlanFeature[];
  bucket_name: 'solo' | 'team' | 'enterprise';
}

export interface Store {
  id: number;
  name: string;
  website: string;
  subdomain: string;
  customDomain: string;
  logoUrl?: string;
  theme?: StoreTheme;
  status: StoreStatus;
  gaId: string;
  shopifyShopOrigin?: string;
  signupSource: SignupSource;
  headerMenu?: StoreHeaderMenu;
  plan?: Plan;
  planId?: string;
  rootUrl: string | null;
}
