import { ShopifyLocalCheckout } from '../../../types/api/entities/shopify';

export interface SetCheckoutAction {
  type: 'SHOPIFY/SET_CHECKOUT';
  payload: {
    checkout: ShopifyLocalCheckout;
  };
}

export function setCheckout(checkout: ShopifyLocalCheckout): SetCheckoutAction {
  return {
    type: 'SHOPIFY/SET_CHECKOUT',
    payload: {
      checkout,
    },
  };
}
