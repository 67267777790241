import { combineReducers } from 'redux';
import { StoreState } from '../state';
import { StoreAction } from '../actions';
import { entitiesReducer } from './entities';
import { uiReducer } from './ui';
import { shopifyReducer } from './shopify';

export const reducer = combineReducers<StoreState, StoreAction>({
  entities: entitiesReducer,
  ui: uiReducer,
  shopify: shopifyReducer,
});
