import { UiState } from '../state/ui';
import { StoreAction } from '../actions';

const initialState: UiState = {
  search: '',
  currentRecipe: undefined,
};

export function uiReducer(state = initialState, action: StoreAction): UiState {
  switch (action.type) {
    case 'UI/SET_SEARCH':
      return {
        ...state,
        search: action.payload,
      };
    case 'UI/SET_CURRENT_RECIPE':
      return {
        ...state,
        currentRecipe: action.payload,
      };

    default:
      return state;
  }
}
