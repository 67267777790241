import { call, put, SagaReturnType, select, takeLatest } from 'redux-saga/effects';
import { ApiService, AxiosWithApi } from '../../services/api';
import { CustomAppProps } from '../../types/app';
import { GetRecipeAction } from '../actions/api/get-recipe';
import { GetRecipesAction } from '../actions/api/get-recipes';
import { setRecipe } from '../actions/entities/set-recipe';
import { setRecipes } from '../actions/entities/set-recipes';
import { setStore } from '../actions/entities/set-store';
import * as qs from 'query-string';
import { setFilters } from '../actions/entities/set-filters';
import { RecipeFilter } from '../../types/api/entities/recipe-filter';
import { RateRecipeAction } from '../actions/api/rate-recipe';
import { updateRecipeRating } from '../actions/entities/update-recipe-rating';
import { setCurrentRecipe } from '../actions/ui/set-current-recipe';
import { PartialRecipe } from '../../types/api/entities/recipe';
import { GetRecipeProductsAction } from '../actions/api/get-recipe-products';
import { setProducts } from '../actions/shopify/set-products';
import { AddCheckoutLineItemsAction } from '../actions/shopify/add-checkout-line-items';
import {
  ShopifyLocalCheckoutLineItem,
  ShopifyLocalCheckout,
} from '../../types/api/entities/shopify';
import { setCheckout } from '../actions/shopify/set-checkout';
import { RecipeProductWithShopifyVariant } from '../state/shopify';
import { LOCAL_STORAGE_CHECKOUT_KEY } from '../reducers/shopify';
import { StoreState } from '../state';

type Store = SagaReturnType<ApiService['getStore']>;
type Recipe = SagaReturnType<ApiService['getRecipe']>;
type Recipes = SagaReturnType<ApiService['getRecipes']>;
type Filters = SagaReturnType<ApiService['getRecipeFilters']>;
type RateResult = SagaReturnType<ApiService['rateRecipe']>;
type ShopifyProductsResult = SagaReturnType<ApiService['getShopifyProductVariantsByIds']>;

function* fetchStore(Api: ApiService) {
  try {
    const store: Store = yield call({ context: Api, fn: Api.getStore }, { expand: ['plan'] });
    yield put(setStore(store));
  } finally {
  }
}
function* fetchRecipe(Api: ApiService, action: GetRecipeAction) {
  try {
    const recipe: Recipe = yield call([Api, Api.getRecipe], action.payload.idOrSlug);
    yield put(setRecipe(recipe));
    yield put(setCurrentRecipe(recipe));
  } finally {
  }
}

function* fetchRecipes(Api: ApiService, action: GetRecipesAction) {
  try {
    const { options = {} } = action.options;

    // Create a copy so we don't mutate the object we're given.
    const actualOptions = { ...options };

    const key = qs.stringify({
      ...options,
      offset: undefined,
      limit: undefined,
    });

    if (!actualOptions.limit) {
      actualOptions.limit = 100;
    }

    if (!actualOptions.offset) {
      actualOptions.offset = 0;
    }

    let previousIds: number[] = [];
    if (action.options.previousIds?.length) {
      previousIds = action.options.previousIds;
    }

    // const { items, /*offset,*/ total } = await api.getRecipes(actualOptions);
    const { items, /*offset,*/ total }: Recipes = yield call(
      [Api, Api.getRecipes],
      action.options.options,
    );
    yield put(
      setRecipes({
        byId: items.reduce((acc: { [id: number]: PartialRecipe }, curr) => {
          acc[curr.id] = curr;
          return acc;
        }, {}),
        query: key,
        value: {
          total,
          ids: [...previousIds, ...items.map((item) => item.id)],
        },
      }),
    );
  } finally {
  }
}

function* fetchFilters(Api: ApiService) {
  try {
    const items: Filters = yield call([Api, Api.getRecipeFilters]);
    yield put(
      setFilters({
        byId: items.reduce((prev: { [id: number]: RecipeFilter }, curr) => {
          prev[curr.id] = curr;
          return prev;
        }, {}),
        allIds: items
          .sort((a, b) => (a.updatedAt > b.updatedAt ? -1 : 1)) // allows us to sort filter drop-downs from most to least recent ... a hack until we allow real sorting of filters
          .map((item) => item.id),
      }),
    );
  } finally {
  }
}

function* rateRecipe(Api: ApiService, action: RateRecipeAction) {
  try {
    const { recipeId, value } = action.payload;

    const result: RateResult = yield call([Api, Api.rateRecipe], recipeId, value);
    yield put(updateRecipeRating(recipeId, value, result));
  } catch (e) {
    if ((e as { status: number }).status === 429) {
      // noop
    } else {
      throw e;
    }
  }
}

const indexBy = <T>(fn: (item: T) => string, list: T[]): Record<string, T> => {
  const result: Record<string, T> = {};

  for (let i = 0; i < list.length; i += 1) {
    const item = list[i];
    if (item) {
      result[fn(item)] = item;
    }
  }

  return result;
};

function* getRecipeProducts(Api: ApiService, action: GetRecipeProductsAction) {
  const variants: ShopifyProductsResult = yield call(
    [Api, Api.getShopifyProductVariantsByIds],
    action.payload.products.map((product) => product.variantId),
  );

  const recipeProductsByVariantId = indexBy(
    (product) => product.variantId,
    action.payload.products,
  );

  const products: RecipeProductWithShopifyVariant[] = [];

  variants.forEach((shopifyVariant) => {
    const recipeProduct = recipeProductsByVariantId[shopifyVariant.id];
    if (recipeProduct) {
      products.push({ recipeProduct, shopifyVariant });
    }
  });

  yield put(setProducts({ products, recipeId: action.payload.recipeId }));
}

const uniq = <T>(list: T[]): T[] => Array.from(new Set<T>(list));

const setCheckoutLineItems = (
  checkout: ShopifyLocalCheckout,
  recipeId: number,
  lineItems: ShopifyLocalCheckoutLineItem[],
): ShopifyLocalCheckout => {
  const newLineItems = checkout.lineItems.slice();

  lineItems.forEach((lineItem) => {
    const existingItem = newLineItems.find((item) => lineItem.variant.id === item.variant.id);

    if (existingItem) {
      existingItem.quantity += lineItem.quantity;
    } else {
      newLineItems.push(lineItem);
    }
  });

  return {
    recipeIds: uniq(checkout.recipeIds.concat(recipeId)),
    lineItems: newLineItems,
  };
};

function* addCheckoutLineItems(action: AddCheckoutLineItemsAction) {
  const state: StoreState = yield select();
  const checkout = setCheckoutLineItems(
    state.shopify.checkout,
    action.payload.recipeId,
    action.payload.lineItems,
  );
  localStorage.setItem(LOCAL_STORAGE_CHECKOUT_KEY, JSON.stringify(checkout));
  yield put(setCheckout(checkout));
}

function* rootSaga({ apiBaseUrl, cookie }: CustomAppProps) {
  const Api = new ApiService(AxiosWithApi({ apiBaseUrl, cookie }));

  // We need to bind handlers to give them the 'Api' method
  const fetchStoreBound = fetchStore.bind(null, Api);
  const fetchRecipeBound = fetchRecipe.bind(null, Api);
  const fetchRecipesBound = fetchRecipes.bind(null, Api);
  const fetchFiltersBound = fetchFilters.bind(null, Api);
  const rateRecipeBound = rateRecipe.bind(null, Api);
  const getRecipeProductsBound = getRecipeProducts.bind(null, Api);

  const addCheckoutLineItemsBound = addCheckoutLineItems.bind(null);

  yield takeLatest('API/GET_STORE', fetchStoreBound);
  yield takeLatest('API/GET_RECIPE', fetchRecipeBound);
  yield takeLatest('API/GET_RECIPES', fetchRecipesBound);
  yield takeLatest('API/GET_FILTERS', fetchFiltersBound);
  yield takeLatest('API/RATE_RECIPE', rateRecipeBound);
  yield takeLatest('API/GET_RECIPE_PRODUCTS', getRecipeProductsBound);

  yield takeLatest('SHOPIFY/ADD_CHECKOUT_LINE_ITEMS', addCheckoutLineItemsBound);
}

export default rootSaga;
