import { ShopifyLocalCheckout } from '../../types/api/entities/shopify';
import { StoreAction } from '../actions';
import { ShopifyState } from '../state/shopify';

export const LOCAL_STORAGE_CHECKOUT_KEY = 'CHECKOUT';

const getOrCreateCheckout = (): ShopifyLocalCheckout => {
  if (typeof window !== 'undefined') {
    const rawCheckout = localStorage.getItem(LOCAL_STORAGE_CHECKOUT_KEY);

    if (rawCheckout) {
      try {
        return JSON.parse(rawCheckout);
      } catch (e) {
        console.error('Error while parsing checkout from local storage');
      }
    }
  }

  return {
    recipeIds: [],
    lineItems: [],
  };
};

const initialState: ShopifyState = {
  cartIsOpen: false,
  checkout: getOrCreateCheckout(),
  productsByRecipeId: {},
};

export const shopifyReducer = (state = initialState, action: StoreAction): ShopifyState => {
  switch (action.type) {
    case 'SET_PRODUCTS':
      return {
        ...state,
        productsByRecipeId: {
          ...state.productsByRecipeId,
          [action.payload.recipeId]: action.payload.products,
        },
      };

    case 'SHOPIFY/SET_CHECKOUT':
      return {
        ...state,
        checkout: action.payload.checkout,
      };

    case 'SHOPIFY/SET_LINE_ITEM_QUANTITY': {
      const newState: ShopifyState = {
        ...state,
        checkout: {
          ...state.checkout,
          lineItems: state.checkout.lineItems.map((item) =>
            item.variant.id === action.payload.variantId
              ? { ...item, quantity: action.payload.quantity }
              : item,
          ),
        },
      };

      localStorage.setItem(LOCAL_STORAGE_CHECKOUT_KEY, JSON.stringify(newState.checkout));

      return newState;
    }

    case 'SHOPIFY/REMOVE_CHECKOUT_LINE_ITEM': {
      const newState: ShopifyState = {
        ...state,
        checkout: {
          ...state.checkout,
          lineItems: state.checkout.lineItems.filter(
            (item) => item.variant.id !== action.payload.variantId,
          ),
        },
      };

      localStorage.setItem(LOCAL_STORAGE_CHECKOUT_KEY, JSON.stringify(newState.checkout));

      return newState;
    }

    case 'SHOPIFY/SET_CART_IS_OPEN': {
      return {
        ...state,
        cartIsOpen: action.payload.cartIsOpen,
      };
    }
  }

  return state;
};
