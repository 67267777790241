import { PartialRecipe } from '../../../types/api/entities/recipe';

export interface SetRecipesActionOptions {
  byId: { [id: number]: PartialRecipe };
  query: string;
  value: {
    ids: number[];
    total: number;
  };
}

export interface SetRecipesAction extends SetRecipesActionOptions {
  type: 'SET_RECIPES';
}

export function setRecipes(options: SetRecipesActionOptions): SetRecipesAction {
  return {
    type: 'SET_RECIPES',
    ...options,
  };
}
