import { Store } from '../../../types/api/entities/store';

export interface SetStoreAction {
  type: 'SET_STORE';
  payload: Store;
}

export function setStore(payload: Store): SetStoreAction {
  return {
    type: 'SET_STORE',
    payload,
  };
}
