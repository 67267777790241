import { RecipeFilter } from '../../../types/api/entities/recipe-filter';

export interface SetFiltersActionOptions {
  byId: Record<number, RecipeFilter>;
  allIds: number[];
}

export interface SetFiltersAction {
  type: 'SET_FILTERS';
  payload: SetFiltersActionOptions;
}

export function setFilters(payload: SetFiltersActionOptions): SetFiltersAction {
  return {
    type: 'SET_FILTERS',
    payload,
  };
}
