import { RecipeProductWithShopifyVariant } from '../../state/shopify';

export interface SetProductsPayload {
  recipeId: number;
  products: RecipeProductWithShopifyVariant[];
}

export interface SetProductsAction {
  type: 'SET_PRODUCTS';
  payload: SetProductsPayload;
}

export function setProducts(payload: SetProductsPayload): SetProductsAction {
  return {
    type: 'SET_PRODUCTS',
    payload,
  };
}
