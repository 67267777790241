import { Recipe } from '../../../types/api/entities/recipe';

export interface SetCurrentRecipeAction {
  type: 'UI/SET_CURRENT_RECIPE';
  payload: Recipe;
}

export function setCurrentRecipe(payload: Recipe): SetCurrentRecipeAction {
  return {
    type: 'UI/SET_CURRENT_RECIPE',
    payload,
  };
}
